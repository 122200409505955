import React, { useState, useContext } from 'react';
import Firebase from '../../Config/Firebase';
import UserContext from '../../Contexts/UserContext';
import { updateUser } from '../../Models/Item';
import Currency from '../../Config/Currency.json';
import Button from '../Shared/Button';
import Select from '../Shared/Select';
import AddProductDialog from './AddProductDialog';
import { Dialog, DialogContent } from '../Shared/Dialog';

const CreateItem = ({ onAdd, toggleEdit, showArchived, setShowArchived }) => {
  const currentUser = Firebase.auth().currentUser;
  const userConfig = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const [showConfig, setShowConfig] = useState(false);
  const [config, setConfig] = useState(userConfig);


  const handleUserChange = (key, value) => {
    let newConfig = { ...config };
    newConfig[key] = value;
    setConfig(newConfig);
  }

  const handleOptionsToggle = () => {
    toggleEdit();
    setShowConfig(!showConfig);
  }

  const configUpdate = (e) => {
    e.preventDefault();
    setLoading(true);
    updateUser(currentUser.uid, JSON.parse(JSON.stringify(config)))
      .then(() => config.updateUserContext(config))
      .catch(err => console.log(err))
      .then(() => setLoading(false));
  }

  const handlelogout = () => {
    Firebase.auth().signOut();
  }

  return (
    <>
      <div className="my-2 flex items-center justify-between">
        <h4 className='text-3xl'>Products</h4>
        <div className="flex gap-1 items-center" role="group" >
          <Button size="sm" variant="secondary" onClick={() => setShowArchived(!showArchived)}>
            {showArchived ? "Show Active" : "Show Archived"}
          </Button>
          <Button size="sm" onClick={handleOptionsToggle}>Options</Button>
          <AddProductDialog onAdd={onAdd} />
          <Button size="sm" variant="danger" onClick={handlelogout}>Logout</Button>
        </div>
      </div>

      <Dialog onOpenChange={setShowConfig} open={showConfig}>
        <DialogContent>
          <form className="flex flex-col gap-3" onSubmit={configUpdate}>
            <div className="col-12">
              <label htmlFor="currency-name" className="form-label">Currency*</label>
              <Select
                id="currency-name"
                name="currency-name"
                onChange={(e) => handleUserChange('currency', e.target.value)} placeholder="Currency"
                defaultValue={config.currency || "₹"}
                required>
                {Currency.map(currency => <option value={currency.symbol}>{currency.name + " - " + (currency.symbol)}</option>)}
              </Select>
            </div>
            <div className="col-12">
              <Button type="submit" disabled={loading}>Save</Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CreateItem;