import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../Contexts/UserContext";
import { getCurrency, humanize, humanizeCurrency } from "../../Lib/Utils";
import { deleteItem, updateItem } from "../../Models/Item";
import ItemForm from "./ItemForm";
import { TrashIcon, PencilIcon, ArchiveIcon, CheckCircleFillIcon } from "@primer/octicons-react";
import clsx from "clsx";

const Item = ({ edit = false, onDelete, onUpdate, ...rest }) => {
  const [item, setItem] = useState(rest.item);
  const user = useContext(UserContext);

  const [deleting, setDeleting] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const handleArchive = (archived = true) => {
    updateItem(item.id, { archived })
      .then(() => {
        const updatedItem = {
          ...item,
          archived,
        };
        onUpdate(updatedItem);
        setItem(updatedItem);
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = () => {
    setDeleting(true);
    deleteItem(item.id)
      .then(() => onDelete(item.id))
      .catch((err) => console.log(err))
      .then(() => setDeleting(false));
  };

  const handleUpdate = (item) => {
    setItem(item);
    setShowForm(false);
  };

  useEffect(() => {
    setItem(rest.item);
  }, [rest.item]);

  const getProgressClass = () => {
    if (item.usagePercentage) {
      if (item.usagePercentage >= 0 && item.usagePercentage < 20)
        return "bg-red-500";
      if (item.usagePercentage > 20 && item.usagePercentage < 50)
        return "bg-yellow-500";
      if (item.usagePercentage > 50 && item.usagePercentage < 70)
        return "bg-blue-500";
      if (item.usagePercentage > 70 && item.usagePercentage < 90)
        return "bg-green-500";
      if (item.usagePercentage > 90) return "bg-green-500";
    }
  };

  return (
    <div
      className={clsx("relative col-span-1 group bg-white rounded-lg p-4 border shadow mt-1", {
        "opacity-50": item.archived,
      })}
    >
      {item.usagePercentage === 100 ? (
        <div className="absolute top-0 right-0 px-2 py-2">
          <div className="bg-green-500 text-white rounded-lg p-1 h-5  flex gap-1 items-center justify-center">
            <CheckCircleFillIcon size={12} className="text-white m-0" /> 
            <span className="text-white text-xs font-semibold">Completed</span>
          </div>
        </div>
      ) : (
        <div className="absolute top-2 right-0 px-2 py-2">
          <div className={`text-white rounded-lg opacity-50 group-hover:opacity-100 transition-opacity duration-300 text-white font-semibold p-1 h-5 text-xs  flex gap-1 items-center justify-center ${getProgressClass()}`} >
            <span>{Math.round(item.usagePercentage)}%</span>
          </div>
        </div>
      )}
      <h5 className="font-semibold mb-0 flex items-center gap-2">
        {item.name}
        <small>
          <span className="bg-yellow-600 px-1.5 py-1 text-white rounded-lg text-xs">
            {getCurrency(user)} {humanizeCurrency(item.price)}
          </span>
        </small>
      </h5>
      <div>
        <small className="text-gray-700">
          Purchased and used for{" "}
          <strong>{humanize(item.daysCount, "days")}</strong>
        </small>
        <p className="text-2xl my-2">
          {getCurrency(user)} {humanizeCurrency(item.perDayCost)}/Day
        </p>
        {item.expectedDailyValue && (
          <>
            {item.daysToGo === 0 && (
              <small className="text-gray-700">
                <em>
                  You've used the product to it's fullest expected daily value
                  of {getCurrency(user)} {item.expectedDailyValue} 🎉
                </em>
              </small>
            )}
            {item.daysToGo > 0 && (
              <small className="text-gray-700">
                <em>
                  Use for <strong>{humanize(item.daysToGo, "days")}</strong> to
                  reach your expected daily value of {getCurrency(user)}{" "}
                  {item.expectedDailyValue}
                </em>
              </small>
            )}
           {item.usagePercentage !== 100 && (
            <div className="max-w-full absolute top-0 left-0 w-full h-full" style={{ height: "7px" }}>
              <div
                className={"h-full rounded-r-lg " + getProgressClass()}
                role="progressbar"
                style={{ width: item.usagePercentage + "%" }}
              ></div>
            </div>
           )}
          </>
        )}
      </div>
      {
        <div className="mt-2 self-end group-hover:opacity-100 opacity-0 transition-opacity duration-300">
          <div className="flex items-center justify-end gap-2">
            {/* Archive Button */}
            {!item.archived ? (
              <button
                className="text-xs text-indigo-600 hover:bg-gray-200 rounded-lg p-1 py-0.5"
                onClick={() => handleArchive(true)}
              >
                <ArchiveIcon size={12} /> Archive
              </button>
            ) : (
              <button
                className="text-xs text-indigo-600 hover:bg-gray-200 rounded-lg p-1 py-0.5"
                onClick={() => handleArchive(false)}
              >
                <ArchiveIcon size={12} /> Unarchive
              </button>
            )}

            <button
              className="text-xs text-indigo-600 hover:bg-gray-200 rounded-lg p-1 py-0.5"
              onClick={() => setShowForm(!showForm)}
            >
              <PencilIcon size={12} /> Edit
            </button>
            <button
              disabled={deleting}
              className="text-xs text-red-600 hover:bg-gray-200 rounded-lg p-1 py-0.5"
              onClick={handleDelete}
            >
              <TrashIcon size={12} /> Delete
            </button>
          </div>
          {showForm && <ItemForm item={item} onUpdate={handleUpdate} />}
        </div>
      }
    </div>
  );
};

export default Item;
