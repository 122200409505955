/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useMemo } from "react";
import { listMyItems } from "../Models/Item";
import CreateItem from "../Components/Items/CreateItem";
import Item from "../Components/Items/Item";
import {
  getCurrency,
  getIndexInArray,
  humanize,
  humanizeCurrency,
} from "../Lib/Utils";
import UserContext from "../Contexts/UserContext";

const Home = () => {
  const user = useContext(UserContext);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [showArchived, setShowArchived] = useState(false);

  const handleEdit = () => setEdit(!edit);

  const sortedItems = useMemo(
    () =>
      items
        .sort((a, b) => a.daysCount - b.daysCount)
        .filter((item) => {
          if (showArchived) return true;
          return !item.archived;
        }),
    [items, showArchived]
  );

  const completedItems = useMemo(
    () => sortedItems.filter((item) => item.usagePercentage === 100),
    [sortedItems]
  );

  const incompleteItems = useMemo(
    () => sortedItems.filter((item) => item.usagePercentage !== 100),
    [sortedItems]
  );

  const total = useMemo(() => {
    const t = sortedItems.reduce((acc, item) => acc + item.perDayCost, 0);
    return Math.round(t * 100) / 100;
  }, [sortedItems]);

  useEffect(() => {
    listMyItems(user.uid)
      .then(({ items }) => {
        setItems(items);
      })
      .then(() => setLoading(false))
      .catch((err) => console.log(err));
  }, []);

  const renderItem = (item, i) => (
    <Item
      item={item}
      key={item.id}
      user={user}
      edit={edit}
      onDelete={onDelete}
      onUpdate={onUpdate}
    />
  );

  const onAdd = (item) => {
    setItems((_items) => {
      _items.unshift(item);
      return _items;
    });
  };

  const onDelete = (itemId) => {
    let newItems = [...items];
    let idx = getIndexInArray(itemId, "id", newItems);
    if (idx > -1) {
      newItems.splice(idx, 1);
    }

    setItems(newItems);
  };

  const onUpdate = (item) => {
    setItems((_items) => {
      return _items.map((i) => (i.id === item.id ? item : i));
    });
  };

  return (
    <div className="px-2 flex flex-col gap-3">
      <CreateItem
        onAdd={onAdd}
        toggleEdit={handleEdit}
        showArchived={showArchived}
        setShowArchived={setShowArchived}
      />
      <div className="border rounded-lg shadow bg-white p-4 mt-1 text-center">
        <strong className="font-semibold">
          <small>You are spending already</small>
        </strong>
        <p className="text-4xl my-2">
          {getCurrency(user)} {humanizeCurrency(total)}/Day
        </p>
        <small className="text-gray-700">
          <em>Make sure this doesn't goes above your daily salary.</em>
        </small>
      </div>
      <div className="flex flex-col gap-3">
        <h3 className="text-lg font-semibold">
          In Progress Goals ({incompleteItems.length})
        </h3>
        <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
          {incompleteItems.map(renderItem)}
        </div>
      </div>

      <div className="flex flex-col gap-3">
        <h3 className="text-lg font-semibold">
          Completed Goals ({completedItems.length})
        </h3>
        <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
          {completedItems.map(renderItem)}
        </div>
      </div>
    </div>
  );
};

export default Home;
