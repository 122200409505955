import Firestore from '../Config/Firestore';
import moment from 'moment';
import { cleanSnapArray, getFirebaseTimestamp, getCurrentDate } from '../Lib/Utils';


let itemsRef = Firestore.collection('items');
let usersRef = Firestore.collection('users');

let processItems = (items) => items.map(processItem);
let processItem = (item) => {
  let startDate = moment(item.date);
  let endDate = moment();
  item.daysCount = endDate.diff(startDate, 'days')|| 1;
  item.dateAgo = startDate.fromNow();
  item.perDayCost = (Math.round((item.price/item.daysCount) * 100) / 100) || 0;
  if (item.expectedDailyValue) {
    item.totalDaysToGo = (item.price/item.expectedDailyValue)
    item.daysToGo = item.totalDaysToGo - item.daysCount;
    item.usagePercentage = (item.daysCount/((item.daysToGo || 1) + item.daysCount)) * 100;
    if (item.usagePercentage > 100) {
      item.usagePercentage = 100;
    }
  }
  return item
}

let listMyItems = (userId) => itemsRef
                                .where('userId', '==', userId)
                                .get()
                                .then(cleanSnapArray)
                                .then(processItems)
                                .then((items) => {
                                  return { items }
                                })



let createItem = (item) => itemsRef
                                .add(item)

let updateItem = (itemId, data) => itemsRef
                                      .doc(itemId)
                                      .update(data)
let deleteItem = (itemId) => itemsRef
                                      .doc(itemId)
                                      .delete();

let checkItem = (item) => itemsRef
                                    .doc(item.id)
                                    .collection("items")
                                    .doc(getCurrentDate())
                                    .set({ time: getFirebaseTimestamp() })
                                    .then(() => itemsRef.doc(item.id).get())
                                    .then(snap => ({id: snap.id, ...snap.data() }))

let updateUser = (userId, data) => usersRef
                                    .doc(userId)
                                    .update(data)
export {
  listMyItems,
  checkItem,
  updateItem,
  deleteItem,
  createItem,
  processItem,
  updateUser
}